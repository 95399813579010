<script setup lang="ts">
const props = defineProps<{
  code: string | undefined;
  label?: string | undefined;
  totalPrice?: number | undefined;
}>();
const { label, totalPrice } = toRefs(props);
</script>
<template>
  <div
    class="c-scheppach-primary-500 b-1 b-scheppach-primary-500 flex items-center justify-between rd bg-scheppach-primary-50 p-2"
  >
    <div class="flex gap-3 items-center justify-between pr-5">
      <span class="text-4.2 font-bold">{{ code || label }}</span>
    </div>
    <SharedPrice
      v-if="totalPrice"
      class="text-4.2 font-bold"
      :value="totalPrice"
    />
  </div>
</template>
